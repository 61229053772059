<template>
  <div class="emoji-container">
    <!-- 所有表情列表 -->
    <div class="emoji-title">表情包</div>
    <div class="all-emoji">
      <div
        class="emoji-item"
        v-for="(item, index) in emojiList"
        :key="index"
        @click="appendEmoji(item)"
      >
        <img :src="item.Icon" :alt="item.name" :title="item.name" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, defineEmits } from "vue";
const emojiList = ref([
  {
    name: "闭嘴",
    Icon: "https://scrm.jzsaas.com/emoji/闭嘴.png",
  },
  {
    name: "酷",
    Icon: "https://scrm.jzsaas.com/emoji/酷.png",
  },
  {
    name: "亲吻-1",
    Icon: "https://scrm.jzsaas.com/emoji/亲吻-1.png",
  },
  {
    name: "微笑-1",
    Icon: "https://scrm.jzsaas.com/emoji/微笑-1.png",
  },
  {
    name: "害怕",
    Icon: "https://scrm.jzsaas.com/emoji/害怕.png",
  },
  {
    name: "酷-1",
    Icon: "https://scrm.jzsaas.com/emoji/酷-1.png",
  },
  {
    name: "亲吻-2",
    Icon: "https://scrm.jzsaas.com/emoji/亲吻-2.png",
  },
  {
    name: "笑",
    Icon: "https://scrm.jzsaas.com/emoji/笑.png",
  },
  {
    name: "害怕-1",
    Icon: "https://scrm.jzsaas.com/emoji/害怕-1.png",
  },
  {
    name: "流汗",
    Icon: "https://scrm.jzsaas.com/emoji/流汗.png",
  },
  {
    name: "热恋",
    Icon: "https://scrm.jzsaas.com/emoji/热恋.png",
  },
  {
    name: "笑-1",
    Icon: "https://scrm.jzsaas.com/emoji/笑-1.png",
  },
  {
    name: "奸笑",
    Icon: "https://scrm.jzsaas.com/emoji/奸笑.png",
  },
  {
    name: "懵B",
    Icon: "https://scrm.jzsaas.com/emoji/懵B.png",
  },
  {
    name: "生病",
    Icon: "https://scrm.jzsaas.com/emoji/生病.png",
  },
  {
    name: "笑哭",
    Icon: "https://scrm.jzsaas.com/emoji/笑哭.png",
  },
  {
    name: "僵尸",
    Icon: "https://scrm.jzsaas.com/emoji/僵尸.png",
  },
  {
    name: "迷茫",
    Icon: "https://scrm.jzsaas.com/emoji/迷茫.png",
  },
  {
    name: "生气",
    Icon: "https://scrm.jzsaas.com/emoji/生气.png",
  },
  {
    name: "静音",
    Icon: "https://scrm.jzsaas.com/emoji/静音.png",
  },
  {
    name: "面无表情",
    Icon: "https://scrm.jzsaas.com/emoji/面无表情.png",
  },
  {
    name: "受伤",
    Icon: "https://scrm.jzsaas.com/emoji/受伤.png",
  },
  {
    name: "眼红",
    Icon: "https://scrm.jzsaas.com/emoji/眼红.png",
  },
  {
    name: "开心",
    Icon: "https://scrm.jzsaas.com/emoji/开心.png",
  },
  {
    name: "魔鬼",
    Icon: "https://scrm.jzsaas.com/emoji/魔鬼.png",
  },
  {
    name: "睡觉",
    Icon: "https://scrm.jzsaas.com/emoji/睡觉.png",
  },
  {
    name: "眨眼",
    Icon: "https://scrm.jzsaas.com/emoji/眨眼.png",
  },
  {
    name: "开心-1",
    Icon: "https://scrm.jzsaas.com/emoji/开心-1.png",
  },
  {
    name: "难过",
    Icon: "https://scrm.jzsaas.com/emoji/难过.png",
  },
  {
    name: "思考",
    Icon: "https://scrm.jzsaas.com/emoji/思考.png",
  },
  {
    name: "震惊",
    Icon: "https://scrm.jzsaas.com/emoji/震惊.png",
  },
  {
    name: "开心-2",
    Icon: "https://scrm.jzsaas.com/emoji/开心-2.png",
  },
  {
    name: "难过-1",
    Icon: "https://scrm.jzsaas.com/emoji/难过-1.png",
  },
  {
    name: "天使",
    Icon: "https://scrm.jzsaas.com/emoji/天使.png",
  },
  {
    name: "震惊-1",
    Icon: "https://scrm.jzsaas.com/emoji/震惊-1.png",
  },
  {
    name: "口罩",
    Icon: "https://scrm.jzsaas.com/emoji/口罩.png",
  },
  {
    name: "呕吐",
    Icon: "https://scrm.jzsaas.com/emoji/呕吐.png",
  },
  {
    name: "头晕",
    Icon: "https://scrm.jzsaas.com/emoji/头晕.png",
  },
  {
    name: "中毒",
    Icon: "https://scrm.jzsaas.com/emoji/中毒.png",
  },
  {
    name: "哭",
    Icon: "https://scrm.jzsaas.com/emoji/哭.png",
  },
  {
    name: "呕吐-1",
    Icon: "https://scrm.jzsaas.com/emoji/呕吐-1.png",
  },
  {
    name: "吐舌",
    Icon: "https://scrm.jzsaas.com/emoji/吐舌.png",
  },
  {
    name: "中毒-1",
    Icon: "https://scrm.jzsaas.com/emoji/中毒-1.png",
  },
  {
    name: "哭-1",
    Icon: "https://scrm.jzsaas.com/emoji/哭-1.png",
  },
  {
    name: "疲惫",
    Icon: "https://scrm.jzsaas.com/emoji/疲惫.png",
  },
  {
    name: "吐舌-1",
    Icon: "https://scrm.jzsaas.com/emoji/吐舌-1.png",
  },
  {
    name: "亲吻",
    Icon: "https://scrm.jzsaas.com/emoji/亲吻.png",
  },
  {
    name: "吐舌-2",
    Icon: "https://scrm.jzsaas.com/emoji/吐舌-2.png",
  },
]);

const emit = defineEmits(["selectEmoji"]);
const appendEmoji = (i) => {
  emit("selectEmoji", i);
};
</script>

<style lang="scss" scoped>
.emoji-container {
  width: 100%;
  padding: 5px 10px 15px;
  box-sizing: border-box;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.emoji-title {
  font-size: 12px;
  margin: 10px 0 5px;
}

.all-emoji {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .emoji-item {
    width: 30px;
    height: 30px;
    margin: 5px 10px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
