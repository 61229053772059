<template>
  <div class="chatCotent">
    <div
      class="message-box"
      ref="msgBox"
      :style="{ 'padding-bottom': Domheight + 'px' }"
    >
      <div v-for="item in messageList" :key="item.time" class="msg-list">
        <div class="msg-me" v-if="item.type == 'me'">
          <div class="msg-t">
            <span>{{ item.time }}</span>
            <div
              class="msg-txt"
              v-html="item.text"
              @click="prviateImg(item.text)"
            ></div>
          </div>
          <el-avatar size="40" :src="item.avatar" />
        </div>
        <div class="msg-you" v-if="item.type == 'you'">
          <el-avatar size="40" :src="item.avatar" />
          <div class="msg-ts" v-if="item.messageType == 3">
            <span class="">{{ item.time }}</span>
            <div
              class="msg-txt"
              v-html="item.content"
              @click="prviateImg(item.content)"
            ></div>
          </div>
          <!-- 欢迎语 -->
          <template v-else>
            <div class="msg-ts" v-if="item.messageType != 7">
              <span class="">{{ item.time }}</span>
              <div class="msg-txt" v-html="item.content.content"></div>
              <ul>
                <li
                  v-for="(init, indexs) in item.faList"
                  :key="init.time"
                  @click="answerQues(init)"
                >
                  {{ indexs + 1 + "." + " " + init.title }}
                </li>
              </ul>
            </div>
          </template>
          <!-- 表单 -->
          <template v-if="item.messageType == 7">
            <div class="msg-from">
              <span>{{ item.time }}</span>
              <div class="from-list">
                <div
                  class="formLabel-list"
                  v-for="(field, index) in item.content.formContent"
                  :key="index"
                >
                  <div class="formLabel">{{ field.formLabel }}</div>
                  <el-input
                    :placeholder="field.formPlaceHolder"
                    v-model="field.value"
                    :disabled="item.disabled"
                  />
                </div>
                <el-button
                  type="primary"
                  :disabled="item.disabled"
                  v-if="!item.disabled"
                  @click="submitContent(item.content.formContent, item)"
                  >提交</el-button
                >
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="message-tag" v-if="tagList?.length" ref="messageButton">
      <div class="message-list">
        <el-button
          type="primary"
          @click="tagSend(it)"
          plain
          v-for="it in tagList"
          :key="it.id"
          >{{ it.label }}</el-button
        >
        <div class="message-icon">
          <el-tooltip
            class="box-item"
            effect="dark"
            content="重置"
            placement="top-start"
          >
            <el-icon size="20" @click="refreshTag"><Refresh /></el-icon>
          </el-tooltip>
        </div>
      </div>
    </div>
    <div class="message-send">
      <div class="msg-button">
        <div class="icon-list">
          <el-popover placement="top-end" trigger="click" width="450">
            <template #reference>
              <svg
                t="1711676094089"
                class="icon"
                viewBox="0 0 1025 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="1538"
                width="22"
                height="22"
              >
                <path
                  d="M512.016 1024C229.232 1024 0.016 794.784 0.016 512 0.016 229.216 229.232 0 512.016 0 794.784 0 1024 229.216 1024 512 1024 794.784 794.784 1024 512.016 1024ZM512.016 64C264.976 64 64.016 264.96 64.016 512 64.016 759.024 264.976 960 512.016 960 759.04 960 960 759.024 960 512 960 264.96 759.04 64 512.016 64ZM510.336 833.456C509.056 833.456 507.744 833.488 506.448 833.488 310.992 833.488 229.024 657.12 225.616 649.552 218.336 633.424 225.488 614.496 241.584 607.216 257.712 599.968 276.576 607.088 283.888 623.088 286.64 629.12 352.928 769.488 506.576 769.488 507.584 769.488 508.576 769.456 509.584 769.456 672.896 767.552 738.368 624.768 739.024 623.344 746.176 607.216 765.024 599.872 781.264 607.152 797.392 614.336 804.672 633.248 797.456 649.408 794.176 656.8 714.208 831.056 510.336 833.456ZM671.504 479.84C636.224 479.84 607.664 451.232 607.664 415.984 607.664 380.768 636.224 352.176 671.504 352.176 706.768 352.176 735.344 380.768 735.344 415.984 735.344 451.232 706.768 479.84 671.504 479.84ZM351.504 479.84C316.224 479.84 287.664 451.232 287.664 415.984 287.664 380.768 316.224 352.176 351.504 352.176 386.768 352.176 415.344 380.768 415.344 415.984 415.344 451.232 386.768 479.84 351.504 479.84Z"
                  p-id="1539"
                ></path>
              </svg>
            </template>
            <Emoji @select-emoji="selectEmoji" />
          </el-popover>
          <UploadImg @update:model-value="updateModelValue" :limit="1">
            <el-icon :size="22"><Picture /></el-icon>
          </UploadImg>
        </div>
        <div class="icon-btn"></div>
      </div>
      <div class="textarea-box">
        <div
          id="message-input"
          @paste="handlePaste"
          contenteditable="true"
          spellcheck="false"
          autofocus
          ref="messageInputDom"
          @input="setInput"
        ></div>
        <span class="send-placholder" v-if="anyContent">请输入内容...</span>
        <div class="send-button">
          <el-button
            :type="anyContent ? 'info' : 'primary'"
            :disabled="anyContent"
            @click="sendMsg"
            >发送</el-button
          >
        </div>
      </div>
    </div>
  </div>
  <el-image-viewer
    v-if="dialogVisible"
    @close="dialogVisible = false"
    hide-on-click-modal
    :url-list="htmlStringUrl"
  ></el-image-viewer>
</template>

<script setup>
import {
  ref,
  onMounted,
  nextTick,
  defineProps,
  defineEmits,
  defineExpose,
  computed,
} from "vue";
import { Picture, Refresh } from "@element-plus/icons-vue";
import Emoji from "./emoji.vue";
import { getNowFormatDate } from "@/util/index";
import UploadImg from "@/components/UploadImg.vue";
import { ElMessage } from "element-plus";

/*自定义输入框dom 绑定ref*/
const messageInputDom = ref(null);
const msgBox = ref(null);
const anyContent = ref(true);
const messageButton = ref(null);

const Domheight = computed(() => messageButton.value?.offsetHeight || 0);

const updateModelValue = (val) => {
  const str = `<img style='max-width:250px;max-height:320px;vertical-align: middle;' src='${val}'>`;
  messageInputDom.value.innerHTML += str;
  anyContent.value = false;
};
const dialogVisible = ref(false);
const htmlStringUrl = ref([]);

const { assistantId, messageList, avatar, tagList } = defineProps({
  assistantId: String,
  messageList: Object,
  avatar: Object,
  tagList: Array,
});
const emit = defineEmits([
  "setMessageList",
  "refreshTagList",
  "disabledFrom",
  "sendMessage",
]);
const prviateImg = (htmlString) => {
  htmlStringUrl.value = extractImageLinks(htmlString);
  if (htmlStringUrl.value.length) dialogVisible.value = true;
};

const extractImageLinks = (inputString) => {
  const imgSrcRegex = /<img.*?src=["'](.*?)["']/g;
  const srcArray = [];
  let match;

  while ((match = imgSrcRegex.exec(inputString)) !== null) {
    srcArray.push(match[1]);
  }
  return srcArray;
};
/*欢迎语回答*/
const answerQues = (value) => {
  let newMessage = {
    type: "me",
    text: value.title,
    avatar:
      "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
    time: getNowFormatDate(),
  };
  emit("setMessageList", newMessage);

  let newMessage2 = {
    type: "you",
    content: value.content,
    avatar: avatar?.data.avator,
    time: getNowFormatDate(),
    messageType: 3,
  };
  emit("setMessageList", newMessage2);
};
/*发送消息*/
const sendMsg = () => {
  // 1.1 先 JSON 化 message 消息内容
  const messageContent = JSON.stringify({
    text: messageInputDom.value.innerHTML,
    toUserId: assistantId,
    messageType: 3,
  });
  // 1.2 再 JSON 化整个消息
  const jsonMessage = JSON.stringify({
    type: "service-chat-message-send",
    content: messageContent,
  });
  // 2. 最后发送消息
  emit("sendMessage", jsonMessage);
  let newMessage = {
    type: "me",
    text: "",
    avatar:
      "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
    time: getNowFormatDate(),
  };
  newMessage.text = messageInputDom.value.innerHTML;
  emit("setMessageList", newMessage);
  messageInputDom.value.innerHTML = "";
  anyContent.value = true;
  scrollDom();
};
/*标签发送*/
const tagSend = (it) => {
  // 1.1 先 JSON 化 message 消息内容
  const messageContent = JSON.stringify({
    text: it.id,
    toUserId: assistantId,
    messageType: 6,
  });
  // 1.2 再 JSON 化整个消息
  const jsonMessage = JSON.stringify({
    type: "service-chat-message-send",
    content: messageContent,
  });
  // 2. 最后发送消息
  emit("sendMessage", jsonMessage);
  let newMessage = {
    type: "me",
    text: it.label,
    avatar:
      "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
    time: getNowFormatDate(),
  };
  emit("setMessageList", newMessage);
  scrollDom();
};
/*表单提交*/
const submitContent = (value, item) => {
  value.forEach((element) => {
    if (!element.value) {
      ElMessage.warning(element.formPlaceholder);
      return;
    }
  });
  let obj = {
    formContent: value,
    formId: item.fromUserId,
  };
  // 1.1 先 JSON 化 message 消息内容
  const messageContent = JSON.stringify({
    text: JSON.stringify(obj),
    toUserId: assistantId,
    messageType: 7,
  });
  // 1.2 再 JSON 化整个消息
  const jsonMessage = JSON.stringify({
    type: "service-chat-message-send",
    content: messageContent,
  });
  // 2. 最后发送消息
  emit("sendMessage", jsonMessage);
  ElMessage.success("提交成功!");
  emit("disabledFrom", item.time);
  scrollDom();
};
/*重置tag*/
const refreshTag = () => {
  emit("refreshTagList");
};
//滚动的底部
const scrollDom = () => {
  nextTick(() => {
    scrollToBottom();
  });
};

/*计算文本是否有内容*/
const setInput = () => {
  if (messageInputDom.value.innerHTML == "") {
    anyContent.value = true;
  } else {
    anyContent.value = false;
  }
};

/*选择表情*/
const selectEmoji = (item) => {
  let emojiImg = `<img src="${item.Icon}"  style="vertical-align: middle;">`;
  messageInputDom.value.innerHTML += emojiImg;
  anyContent.value = false;
};

/*复制的带样式的文本变为纯文本了*/
const handlePaste = (e) => {
  e.preventDefault();
  let text;
  let clp = (e.originalEvent || e).clipboardData;
  if (clp === undefined || clp === null) {
    text = window.clipboardData.getData("text") || "";
    if (text !== "") {
      if (window.getSelection) {
        var newNode = document.createElement("span");
        newNode.innerHTML = text;
        window.getSelection().getRangeAt(0).insertNode(newNode);
      } else {
        document.selection.createRange().pasteHTML(text);
      }
    }
  } else {
    text = clp.getData("text/plain") || "";
    if (text !== "") {
      document.execCommand("insertText", false, text);
    }
  }
};

onMounted(() => {
  scrollToBottom();
  keydownSend();
});
// 回车发送消息
const keydownSend = () => {
  document
    .getElementById("message-input")
    .addEventListener("keydown", function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        if (event.shiftKey) {
          // 在Firefox中，需要手动插入换行符
          document.execCommand("insertLineBreak");
        } else {
          sendMsg();
        }
      }
    });
};

/*滚动到底部*/
const scrollToBottom = () => {
  const chatLog = msgBox.value;
  if (chatLog) {
    chatLog.scrollTop = chatLog.scrollHeight;
  }
};
defineExpose({ scrollDom });
</script>

<style lang="scss" scoped>
.msg-t {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  span {
    font-size: 14px;
    color: #909399;
    padding-right: 10px;
  }
}
.msg-ts {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  span {
    font-size: 14px;
    color: #909399;
    padding-left: 10px;
  }
  ul {
    li {
      list-style: none;
      padding-left: 10px;
      font-size: 14px;
      letter-spacing: 2px;
      line-height: 1.5;
      cursor: pointer;
      text-align: left;
      text-decoration: underline;
      color: #72a0eb;
      margin-top: 5px;
    }
  }
}
.msg-from {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: calc(100% - 40px);
  span {
    font-size: 14px;
    color: #909399;
    padding-left: 10px;
  }
  .from-list {
    width: 80%;
    border-radius: 4px;
    margin-top: 10px;
    margin-left: 10px;
    padding: 20px;
    border: 1px solid #dcdfe6;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    .formLabel-list {
      color: #606266;
      padding-bottom: 10px;
      font-size: 14px;
      display: flex;
      width: 100%;
      .formLabel {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 5px;
        white-space: nowrap;
      }
    }
  }
}
.chatCotent {
  width: 579px;
  border-right: 1px solid #ececec;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  .message-tag {
    position: absolute;
    left: 0;
    bottom: 27.9%;
    width: calc(100% - 2px);
    background-color: #fff;
    box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.1);
    .message-list {
      position: relative;
      padding-right: 40px;
      width: calc(100% - 40px);
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      .el-button {
        margin: 10px;
      }
      .message-icon {
        width: 40px;
        position: absolute;
        height: calc(100% - 15px);
        right: 0;
        top: 0;
        display: flex;
        padding-top: 15px;
        justify-content: center;
        align-items: flex-start;
        .el-icon {
          cursor: pointer;
        }
      }
    }
  }
  .message-box {
    height: 70%;
    border-bottom: 1px solid #ececec;
    overflow-y: auto;

    .msg-list {
      margin-top: 10px;
      .msg-time {
        text-align: center;
        color: #999;
        font-size: 14px;
        margin: 18px 0;
      }
      .msg-me {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        padding-right: 20px;
        margin-bottom: 10px;
        .el-image {
          width: 40px;
          height: 40px;
        }
        .msg-txt {
          max-width: 320px;
          padding: 4px 6px;
          border-radius: 4px;
          background-color: rgb(180, 224, 255);
          color: #000;
          font-size: 14px;
          margin-right: 10px;
          line-height: 1.5;
          letter-spacing: 2px;
          text-align: left;
          word-wrap: break-word;
        }
        .msg-img {
          margin-right: 10px;
          img {
            max-width: 320px;
            max-height: 320px;
          }
        }
        .msg-all {
          max-width: 320px;
          padding: 9px;
          font-size: 14px;
          margin-right: 10px;
          border-radius: 4px;
          background-color: rgb(180, 224, 255);
          img {
            max-width: 320px;
            max-height: 320px;
          }
        }
      }
      .msg-you {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 20px;
        margin: 10px 0;
        .el-image {
          width: 40px;
          height: 40px;
        }
        .msg-txt {
          max-width: 320px;
          padding: 4px 6px;
          border-radius: 4px;
          background-color: rgb(164, 234, 192);
          color: #000;
          font-size: 14px;
          line-height: 1.5;
          margin-left: 10px;
          letter-spacing: 2px;
          text-align: left;
          word-wrap: break-word;
        }
        .msg-img {
          margin-left: 10px;
          .el-image {
            max-width: 320px;
            max-height: 320px;
          }
        }
        .msg-all {
          max-width: 320px;
          padding: 9px;
          font-size: 14px;
          margin-left: 10px;
          border-radius: 4px;
          background-color: rgb(164, 234, 192);
          img {
            max-width: 320px;
            max-height: 320px;
          }
        }
      }
    }
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #d8d8d8;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #d8d8d8;
    }
    &::-webkit-scrollbar-thumb:horizontal:start {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:horizontal:end {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  .message-send {
    height: 30%;
    .msg-button {
      padding: 15px;
      display: flex;
      justify-content: space-between;
      .icon-list {
        display: flex;
        .el-icon {
          cursor: pointer;
          margin-right: 20px;
        }
        .icon {
          margin-right: 20px;
          cursor: pointer;
        }
      }
      .icon-btn {
        display: flex;
        cursor: pointer;
        align-items: center;
        .icon {
          margin-right: 5px;
          font-size: 15px;
          color: #333;
        }
      }
    }
    .textarea-box {
      height: calc(100% - 56px);
      position: relative;
      margin: 0 auto;
      #message-input {
        width: 100%;
        height: 100%;
        position: absolute;
        text-align: left;
        left: 0;
        top: 0;
        color: #515a6e;
        overflow-y: auto;
        box-sizing: border-box;
        resize: none;
        line-height: 1.5;
        padding: 10px 15px;
        &::-webkit-scrollbar {
          width: 2px;
        }
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }
        &::-webkit-scrollbar-thumb {
          background: #d8d8d8;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #d8d8d8;
        }
        &::-webkit-scrollbar-thumb:horizontal:start {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        &::-webkit-scrollbar-thumb:horizontal:end {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
        &::placeholder {
          font-size: 15px;
        }
      }
      .send-placholder {
        position: absolute;
        top: 10px;
        left: 15px;
        color: #909399;
      }
      .send-button {
        position: absolute;
        right: 20px;
        bottom: 20px;
      }
    }
  }
}
[contenteditable]:focus {
  outline: none;
}
</style>
